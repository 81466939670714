body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@media (max-width: 767px) {
  .hidden-mobile {
    display: block;
  }
}@media (min-width: 767px) {
  .hidden-mobile {
    display: none;
  }
}

.mobile {
  position: fixed;width:60px;height:60px;bottom:40px;right:90px;background-color:#118ab2;color:#FFF !important;border-radius:50px;text-align:center;font-size:30px;box-shadow: 2px 2px 3px #999;z-index:100;
}

.mobile-float {
  margin-top: 16px;
}

.whatsapp {
  position: fixed;width:60px;height:60px;bottom:40px;right:20px;background-color:#25d366;color:#FFF !important;border-radius:50px;text-align:center;font-size:30px;box-shadow: 2px 2px 3px #999;z-index:100;
}

.whatsapp-float {
  margin-top: 16px;
}