.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
a.nav-link {
  font-weight: 500;
}
a, a:active, a:visited {
  color: #118AB2 !important;
  border-color: #118AB2 !important;
}
a:hover {
  color: #13586F !important;
  border-color: #13586F !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

* {text-align: left;}

.navbar-brand {
  background-image: url("./assets/logo csmd.svg");
  color: transparent !important;
  background-size: cover;
  display: block;
  height: 64px;
  width: 220px;
}

.navbar-brand:hover {
  color: transparent !important;
}

.navbar-google {
  background-image: url("./assets/googleCloud.png");
  color: transparent !important;
  background-size: cover;
  display: block;
  height: 64px;
  width: 220px;
}

.navbar-google:hover {
  color: transparent !important;
}

.twitter_link, .facebook_link {
  display: inline-block;
  height: 20px;
  width: 24px;
  overflow: hidden;
  color: transparent;
  text-indent: -5000px;
  margin-top: 0.5em;
  margin-left: 1em;
}
.facebook_link {
  background-image: url(./assets/linkedin.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 21px;
  width: 21px;
}
.twitter_link {
  height: 20px;
  width: 24px;
  background-image: url(./assets/twitter.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
div#contacto {
  margin-top: -5em;
  position: absolute;       
}
.page-contacto {
  background-image: url(./assets/Contacto_left.png);
  background-repeat: no-repeat;
}
.condiciones {
  z-index: 1;    
  margin-top: 5em;
  background-color: #fdd068;
  font-size: 1.5em;
}
.condiciones ul{
  list-style: none;
}
.condiciones li{
  padding: 1em;
}
.page-docentes .d-flex {
  justify-content: center;
  margin-top: -4em;
}
.page-docentes .docentes-header{
  background-color: #e8476f;
  padding: 3em;
  background-image: url(./assets/Profesores_left.png);
  background-repeat: no-repeat;
  background-position: 3em;
}
.page-docentes .docentes-header .text-wrap {
  width: 50%;
  margin: 5em auto;
  color: #fff;
}
.page-docentes .docentes-header .text-wrap h2 {
  font-size: 3em;
  padding-bottom: 0.5em;
}

.page-docentes .docentes-header .text-wrap p {
  font-size: 1.4em;
}
.docente {
  display: list-item;
  list-style: none;
  width: 260px;
}
.docente .nombre {
  font-weight: 700;
  text-decoration: underline;
}
.docenteFooter a {
  color: #fff !important;
  font-weight: 700;
  text-decoration: underline;
}
.docenteFooter p {
  text-align: center;
  padding-top: 1em;
  font-size: 1.2em;
}
.docente img{
  width: 250px;
  height: 250px;
}
.presentation-title {
  font-size: 3em;
  padding-bottom: 1em;
}
.presentation-subtitle {
  font-size: 1.3em;
  padding-bottom: 2em;
}
.page-header {
  background-image: url('./assets/BG.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.page-header .buttons {
  text-align: left;
  padding: 1em;
}
.page-header .btn-primary, .page-oportunidades-empleo .btn-primary, .page-contacto .btn-primary, input[type="submit"] {
  margin-right: 1em;
  padding: 0.5em;
  font-size: 1em;
  color: #fff !important;
  background-color: #118AB2;
  border-color: #118AB2 !important;
}
.page-header .btn-primary:hover, .page-oportunidades-empleo .btn-primary:hover, .page-contacto .btn-primary:hover {
  border-color: #13586F !important;
  background-color: #13586F !important;
}
.page-header .btn-primary.btn-download, .page-header .btn-primary.btn-download:active, .page-header .btn-primary.btn-download:focus-visible, .page-header .btn-primary.btn-download:focus, .CookieConsent .btn-primary.btn-download, .CookieConsent .btn-primary.btn-download:active, .CookieConsent .btn-primary.btn-download:focus-visible, .CookieConsent .btn-primary.btn-download:focus {
  background: transparent !important;
  margin-left: 100px;
  outline: 0;
  color: #0069d9 !important;
  border: 0;
  text-decoration: underline;
  box-shadow: none;
}
.page-header .btn-primary.btn-download:hover, .CookieConsent .btn-primary.btn-download:hover {  
  color: #13586F !important;
} 
.page-header ul {
  padding: 1em 1em 0.3em;
  margin-top: 1em;
  list-style: none;
  text-align: left;
  background-color: #fdd068;
}
.page-header ul li {
  text-align: left;
  padding-left: 20px;
  margin-bottom: 10px;
}
iframe {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.page-paraquien .container-fluid{
  background-color: #f8f8f8;
  padding-top: 5em;
  padding-bottom: 7em;
  background-position-y: 3em;
  background-image: url("./assets/Circulo.png");
  background-repeat: no-repeat;
}
.page-paraquien h2 {
  font-size: 2.5em;
  margin-bottom: 1em;
}
.page-objetivos {
  margin-top: 1em;
  padding: 3em 0;
}
.page-objetivos ul {
  padding-left: 0;
  list-style: none;
}
.page-objetivos ul li{
  padding-left: 30px;
  font-size: 1.3em;
  border-bottom: 1px solid #eee;
  list-style: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.page-objetivos ul li i{
  color: lightgreen;
}
.pills {
  padding-top: 1em;
  margin-bottom: 1em;
}
.pills p {
  text-align: left;
  font-size: 1em;
}
.pillsWrapper.container {
  margin-top: -7em;
}
.pills .col {
  margin: 1em;
  border-radius: 5%;
  box-shadow: 0 0 10px #ccc;
  background-color: #fff;
}
.pills .wrap {
  padding-bottom: 4em;
}
.pills .link {
  position: absolute;
  bottom: 1.5em;
}
.pills .link a {
  text-decoration: underline;
}
.pills .perfil .link a{
  color: #50b693; 
}
.pills .mercado .link a{
  color: #fdd068; 
}
.pills .salidas .link a{
  color: #e8476f; 
}
.pills .perfil h4 {
  color: #50b693;
  background-image: url("./assets/fa_graduation-cap.png");
}
.pills .salidas h4 {
  color: #e8476f;
  background-image: url("./assets/ls_bag.png");
}
.pills .mercado h4 {
  color: #fdd068;  
  background-image: url("./assets/mdi_seal.png");
}
.pills h4 {
  background-position: center 2em;
  background-repeat: no-repeat;
  padding-top: 5em;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
  line-height: 1.5em;
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 2em;
}
.page-consiste .content-right, .page-comofunciona .content-right, .page-oportunidades-empleo .content-right  {
  text-align: center;
}
.buttons {
  text-align: center;
  padding: 1em;
}
.page-oportunidades-empleo-inscribirme{
  margin: 0 auto;
  display: inline-block;
}
.page-comofunciona {
  position: relative;
  padding-top: 5em;
  background-image: url(./assets/Estudiaras_left.png);
  background-repeat: no-repeat;
}
.page-comofunciona .row{
  padding: 3em 0;
}
.page-comofunciona h2 {
  font-size: 3em;
  text-align: center;
  padding-bottom: 0.5em;
}
.page-comofunciona p {
  font-size: 1.5em;
}
.docenteFooter.container {
  background: #e8476f;
  padding: 2em;
  margin-bottom: 2em;
  margin-top: -2.5em;
}
.docente-wrap {
  margin-left: 10px;
  margin-right: 30px;
  box-shadow: 1px 1px 3px #aaa;
  background: #fff;
  margin-top: -20px;
  display: block;
  z-index: 1;
  position: relative;
  padding: 0.5em 1em;
  margin-bottom: 0.5em;
  height: calc(100% - 250px);
}
.page-comofunciona .temario {
  font-size: 1.2em;
  color: #fff;
  background-color: #118ab2;
  padding-top: 2em;
  padding-left: 2em;
}
.page-comofunciona .temarioVideo {
  background-color: #fdd068;
  text-align: center;
  padding-top: 6em;
}
.page-comofunciona .temarioVideo p{
  margin-bottom: 0;
}
.page-comofunciona .temario ul li{
  border-bottom: 1px solid #fff;
  padding: 0.8em 0;
}
.page-comofunciona iframe {
  margin-bottom: 2em;
}
.page-comofunciona .temarioVideo p {
  text-align: center;
}
.page-comofunciona .temario .link a {
  color: #fff !important;
  text-decoration: underline;
  text-align: center;
}
.page-comofunciona .temario .link {
  font-size: 0.8em;
  text-align: center;
}
.formularioWrap {
  background-color: #91C8DB;
}
.formularioWrap form input[type="submit"] {
  margin: 1em auto;
  display: block;
  border-inline-width: 0;
  box-shadow: none;
  outline: 0;
  appearance: none;
  border: 0;
}
input[type="submit"]:disabled {
  background: #888888;
} 
.page-footer {
  position: relative;
  margin-top: 5em;
  padding: 2em;
  background-color: #13586f;
  background-image: url(./assets/footer_left.png);
  background-repeat: no-repeat;
  background-position: bottom left;
}
.page-footer .ball-azul{
  position: absolute;
  right: 2em;
  top: 1em;
  background-image: url(./assets/footer_right.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 3em;
  height: 6em;
}
.page-footer .align-items-center {
  justify-content: center;
  margin-bottom: 3em;
}
.footer_links {
  position: absolute;
  z-index: 1;
  right: 8em;
  bottom: 2em;
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .formularioWrap {
    padding-right: 0;
  }
  .formularioWrap .container {
    padding: 1em 3em 1em 8em;
  }
  .formularioWrap .container h3 {
    font-size: 2.5em;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .formularioWrap .container > p {
    font-size: 1.5em;
    padding-bottom: 1em;
  }
  .formularioWrap .container form p {
    margin-bottom: 0;
    margin-top: 0em;
  }
  .formularioWrap .container form label {
    margin: 0;
    padding: 0;
    font-size: 1.2em;
    padding-bottom: 0.4em;
  }
  .formularioWrap .container form input[type=text], .formularioWrap .container form input[type=email], .formularioWrap .container form textarea {
    width: 100%;
    margin-bottom: 1em;
    padding: 1em;
    border: 0;
    outline: 0;
  }
  .page-contacto {
    position: relative;
    padding-top: 5em;
    background-position-y: 3em;
  }
  .condiciones {
    padding: 1em;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .formularioWrap .container {
    padding: 1em 3em 1em 20%;
  }
  .condiciones {
    width: 50%;
    box-sizing: border-box;
    position: absolute;
  }
  .navbar-brand {
    margin-left: 10%;
  }
  .page-header .content-right {
    margin-top: 12em;
    font-size: 1em;
  }
  .page-header .row {
    height: 100vh;
  }
  .page-consiste {
    margin-top: 5em;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #50b69325;
  }
  .page-oportunidades-empleo p, .page-paraquien p, .page-paraquien ul li {
    font-size: 1.3em;
  }
  .page-oportunidades-empleo {
    margin-top: 4em;
    padding-top: 2em;
    padding-bottom: 2em;
    background-color: #fee4a7;
    position: relative;
  }
  .page-oportunidades-empleo .ball-verde, .page-contacto .ball-verde{
    display: block;
    position: absolute;
    top: -4em;
    left: 1em;
    background-image: url("./assets/circulos_verde.svg");
    background-repeat: no-repeat;
    width: 4em;
    height: 8em;
    background-size: 100%;
  }
  .page-contacto .ball-verde{
    right: 1em;
    left: initial;
    background-position-y: 4em;
    z-index: 1;
    height: 12em;
  }
  .page-comofunciona .circle-rosa{
    display: block;
    position: absolute;
    top: -4em;
    right: 0em;
    background-image: url("./assets/doblerojo.svg");
    width: 4em;
    height: 8em;
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .page-comofunciona .nube{
    display: block;
    position: absolute;
    top: 2em;
    right: 10%;
    background-image: url("./assets/nubecilla.svg");
    background-repeat: no-repeat;
    width: 8em;
    height: 8em;
    background-size: 100%;
  }
  .page-consiste .container h2, .page-oportunidades-empleo .container h2{
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 1em;
  }
  
  .page-consiste .container p {
    font-size: 1.3em;
    text-align: center;
  }
  .container-fluid .content-left{
    padding-left: 3em;
    max-width: 600px;
    text-align: left;
  }
  .content-center {
    padding-left: 3em;
    text-align: left;
  }
  .page-objetivos {
    margin-top: 3em;
  }
  .page-objetivos h2 {
    margin-bottom: 1em;
    font-size: 2.5em;
  }
  .page-objetivos iframe {
    margin-top: 6em;
  }
}
.buttons .btn-primary {
  color: #fff !important;
}
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { }